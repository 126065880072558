var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.workerSelected
      ? _c(
          "div",
          [
            _c(
              "div",
              [
                _c(
                  "back-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.clearWorker()
                      },
                    },
                  },
                  [_vm._v("Back to Workers")]
                ),
              ],
              1
            ),
            _c("worker-view"),
          ],
          1
        )
      : _c("div", [_c("worker-dash")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }