var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.workers, function (worker, index) {
      return _c(
        "b-row",
        { key: index },
        [
          _c(
            "b-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "b-card",
                {
                  staticClass: "mb-1 shadow",
                  staticStyle: { "min-width": "200px" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center" },
                    [
                      _c("b-avatar", { attrs: { size: "sm" } }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            color: "#38455D",
                            "margin-left": "8px",
                          },
                        },
                        [_vm._v(_vm._s(worker.name))]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c("worker-age-range-assignments", {
                attrs: { "worker-id": worker.worker_id },
              }),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }