var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "8" } },
            [
              _c(
                "b-row",
                { staticClass: "white-card shadow p-3 py-4 m-0 mb-4" },
                [
                  _c(
                    "b-col",
                    { staticClass: "pl-2 pr-0", attrs: { cols: "3" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column justify-content-between pl-2",
                          staticStyle: { "border-left": "4px solid #5A31C1" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "avo-text-normal-grey pb-1" },
                            [_vm._v("Average time to close")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center stat-value-text",
                            },
                            [
                              _vm._v(_vm._s(_vm.stats.avgTimeToClose) + " "),
                              _c(
                                "div",
                                { staticClass: "stat-subtext ml-2 mb-1" },
                                [_vm._v("days")]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "pl-0 pr-0", attrs: { cols: "3" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column justify-content-between pl-2",
                          staticStyle: { "border-left": "4px solid #15ACC1" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "avo-text-normal-grey pb-1" },
                            [_vm._v("Average claims worked")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center stat-value-text",
                            },
                            [
                              _vm._v(_vm._s(_vm.stats.avgClaimsWorked) + " "),
                              _c(
                                "div",
                                { staticClass: "stat-subtext ml-2 mb-1" },
                                [_vm._v("per day")]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "pl-0 pr-0 ", attrs: { cols: "3" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column justify-content-between pl-2",
                          staticStyle: { "border-left": "4px solid #EEC05A" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "avo-text-normal-grey pb-1" },
                            [_vm._v("Average tasks completed")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center stat-value-text",
                            },
                            [
                              _vm._v(_vm._s(_vm.stats.avgTasksCompleted) + " "),
                              _c(
                                "div",
                                { staticClass: "stat-subtext ml-2 mb-1" },
                                [_vm._v("per day")]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "pl-2 pr-0", attrs: { cols: "3" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column justify-content-between pl-2",
                          staticStyle: { "border-left": "4px solid #FD4739" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "avo-text-normal-grey pb-1" },
                            [_vm._v("Average issues")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center stat-value-text",
                            },
                            [
                              _vm._v(_vm._s(_vm.stats.avgIssuesPerClaim) + " "),
                              _c(
                                "div",
                                { staticClass: "stat-subtext ml-2 mb-1" },
                                [_vm._v("per claim")]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("b-card", { staticClass: "white-card shadow mb-4" }, [
                        _c(
                          "div",
                          [
                            _c(
                              "b-row",
                              { staticClass: "mb-4" },
                              [
                                _c(
                                  "b-col",
                                  { staticClass: "summary-card-header-txt" },
                                  [_vm._v(" Age Range Assignments ")]
                                ),
                                _c(
                                  "b-col",
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-toggle",
                                            rawName:
                                              "v-b-toggle.collapse-age-range-assignments",
                                            modifiers: {
                                              "collapse-age-range-assignments": true,
                                            },
                                          },
                                        ],
                                        staticClass: "float-right",
                                        staticStyle: { padding: "0px" },
                                        attrs: { variant: "link" },
                                      },
                                      [
                                        !_vm.ageRangeAssignmentsVisible
                                          ? _c("i", {
                                              staticClass:
                                                "fas fa-chevron-down",
                                            })
                                          : _c("i", {
                                              staticClass: "fas fa-chevron-up",
                                            }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-collapse",
                              {
                                attrs: { id: "collapse-age-range-assignments" },
                                model: {
                                  value: _vm.ageRangeAssignmentsVisible,
                                  callback: function ($$v) {
                                    _vm.ageRangeAssignmentsVisible = $$v
                                  },
                                  expression: "ageRangeAssignmentsVisible",
                                },
                              },
                              [
                                _vm.ageRangeAssignmentsVisible
                                  ? _c("worker-list-age-range-assignments", {
                                      attrs: {
                                        workers: _vm.selectedWorkersBucket,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-card",
                { staticClass: "white-card shadow mb-4" },
                [
                  _c("div", { staticClass: "summary-card-header-txt" }, [
                    _vm._v(" Claims "),
                  ]),
                  _c("donut-with-bar", {
                    attrs: {
                      "chart-data": _vm.claimsChartData,
                      "data-loading": _vm.claimsChartLoading,
                    },
                  }),
                ],
                1
              ),
              _c("b-card", { staticClass: "white-card shadow mb-4" }, [
                _c("div", { staticClass: "summary-card-header-txt" }, [
                  _vm._v(" Claims by Age "),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "chart-container",
                    staticStyle: { height: "350px" },
                  },
                  [
                    _vm.claimsByAgeChartLoading
                      ? _c(
                          "div",
                          { staticClass: "spinner-container" },
                          [_c("b-spinner")],
                          1
                        )
                      : _vm._e(),
                    _c("line-chart", {
                      attrs: {
                        chartData: _vm.claimsByAgeChartData,
                        styles: {
                          width: "100%",
                          height: "100%",
                          position: "relative",
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("b-card", { staticClass: "white-card shadow mb-4 pr-2" }, [
                _c("div", { staticClass: "summary-card-header-txt" }, [
                  _vm._v(" Average Time to Close by Worker "),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "chart-container",
                    staticStyle: { height: "300px" },
                  },
                  [
                    _vm.timeToCloseChartLoading
                      ? _c(
                          "div",
                          { staticClass: "spinner-container" },
                          [_c("b-spinner")],
                          1
                        )
                      : _vm._e(),
                    _c("horizontal-bar-chart", {
                      attrs: {
                        chartData: _vm.timeToCloseChartData,
                        styles: {
                          width: "100%",
                          height: "100%",
                          position: "relative",
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("b-card", { staticClass: "white-card shadow mb-4 pr-2" }, [
                _c("div", { staticClass: "summary-card-header-txt" }, [
                  _vm._v(" Claims Worked "),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "chart-container",
                    staticStyle: { height: "300px" },
                  },
                  [
                    _vm.claimsWorkedChartLoading
                      ? _c(
                          "div",
                          { staticClass: "spinner-container" },
                          [_c("b-spinner")],
                          1
                        )
                      : _vm._e(),
                    _c("horizontal-bar-chart", {
                      attrs: {
                        chartData: _vm.claimsWorkedChartData,
                        styles: {
                          width: "100%",
                          height: "100%",
                          position: "relative",
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "b-card",
                { staticClass: "white-card shadow mb-4" },
                [
                  _c("div", { staticClass: "summary-card-header-txt" }, [
                    _vm._v(" Top Claim Issues "),
                  ]),
                  _c("donut-with-bar", {
                    attrs: {
                      "chart-data": _vm.issueChartData,
                      "data-loading": _vm.issueChartLoading,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "pl-0 pl-xl-2", attrs: { cols: "4" } },
            [
              _c(
                "div",
                { staticClass: "worker-search-box shadow" },
                [
                  _c(
                    "b-input-group",
                    { staticClass: "search-group mb-4" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "mb-2",
                          staticStyle: {
                            "font-size": "16px",
                            "font-weight": "300",
                            color: "white",
                          },
                        },
                        [_vm._v("By Practice")]
                      ),
                      _c("multiselect", {
                        attrs: {
                          options: _vm.practices,
                          "track-by": "practice_id",
                          label: "name",
                          "show-labels": false,
                          placeholder: "Select a practice",
                        },
                        model: {
                          value: _vm.selectedPractice,
                          callback: function ($$v) {
                            _vm.selectedPractice = $$v
                          },
                          expression: "selectedPractice",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-input-group",
                    { staticClass: "search-group mb-4" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "mb-2",
                          staticStyle: {
                            "font-size": "16px",
                            "font-weight": "300",
                            color: "white",
                          },
                        },
                        [_vm._v("By Worker Name")]
                      ),
                      _c("multiselect", {
                        attrs: {
                          options: _vm.claimWorkers,
                          "track-by": "worker_id",
                          label: "name",
                          multiple: true,
                          "show-labels": false,
                          placeholder: "Select or de-select workers",
                        },
                        model: {
                          value: _vm.selectedWorkersBucket,
                          callback: function ($$v) {
                            _vm.selectedWorkersBucket = $$v
                          },
                          expression: "selectedWorkersBucket",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "mb-3",
                      staticStyle: {
                        "font-size": "20px",
                        "font-weight": "300",
                        color: "white",
                      },
                    },
                    [_vm._v("Worker's Assigned Claims")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column justify-content-start align-items-center",
                    },
                    _vm._l(_vm.selectedWorkersBucket, function (worker, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "worker-card cursor-pointer",
                          on: {
                            click: function ($event) {
                              return _vm.selectWorker(index)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c("b-avatar"),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "#38455D",
                                    "margin-left": "8px",
                                  },
                                },
                                [_vm._v(_vm._s(worker.name))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-column align-items-end justify-content-center",
                            },
                            [
                              _c("div", { staticClass: "stat-value-text" }, [
                                _vm._v(_vm._s(worker.assignments)),
                              ]),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "14px",
                                    color: "#929BAA",
                                  },
                                },
                                [_vm._v("Assigned Claims")]
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              _c(
                "b-card",
                { staticClass: "white-card shadow mb-4" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-row justify-content-start pb-4",
                    },
                    [
                      _c("div", { staticClass: "summary-card-header-txt" }, [
                        _vm._v(" Issues by Worker "),
                      ]),
                    ]
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-3" },
                    [
                      _c("b-col", { attrs: { cols: "4" } }, [
                        _c("div", { staticClass: "small-head-txt-med" }, [
                          _vm._v("Worker"),
                        ]),
                      ]),
                      _c(
                        "b-col",
                        { staticClass: "text-right", attrs: { cols: "3" } },
                        [
                          _c("div", { staticClass: "small-head-txt-med" }, [
                            _vm._v("Rejected"),
                          ]),
                        ]
                      ),
                      _c("b-col", { attrs: { cols: "5" } }, [
                        _c("div", { staticClass: "small-head-txt-med" }, [
                          _vm._v("Top Reason"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._l(_vm.workerIssuesInfo, function (item) {
                    return _c(
                      "b-row",
                      { key: item.workerID, staticClass: "mb-3" },
                      [
                        _c(
                          "b-col",
                          {
                            staticClass: "small-head-txt",
                            attrs: { cols: "4" },
                          },
                          [_vm._v(_vm._s(item.workerName))]
                        ),
                        _c(
                          "b-col",
                          {
                            staticStyle: {
                              color: "var(--blue)",
                              "font-weight": "500",
                              "text-align": "right",
                            },
                            attrs: { cols: "3" },
                          },
                          [_vm._v(_vm._s(item.rejectedPercent) + "%")]
                        ),
                        item.topReason
                          ? _c(
                              "b-col",
                              {
                                staticClass: "small-head-txt",
                                attrs: { cols: "5" },
                              },
                              [
                                _vm._v(
                                  _vm._s(item.topReason) +
                                    "(" +
                                    _vm._s(item.topReasonPercent) +
                                    "%)"
                                ),
                              ]
                            )
                          : _c(
                              "b-col",
                              {
                                staticClass: "small-head-txt",
                                attrs: { cols: "5" },
                              },
                              [_vm._v("N/A")]
                            ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex mb-4" }, [
      _c(
        "div",
        {
          staticClass: "avo-page-header-text",
          staticStyle: { "margin-right": "30px" },
        },
        [_vm._v(" Worker's Performance ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }